import React, { useEffect, useState } from "react";
import style from "./MainHeader.module.css";
import Avatar from "@mui/material/Avatar";
import CustomAppBar from "../CustomAppBar/CustomAppBar";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import { styled, useTheme } from "@mui/material/styles";
import { Toolbar, IconButton } from "@mui/material";
import { API } from "../../utils/API";
import { parseJwt } from "../../utils/utils";

interface HeaderProps {
    open: boolean;
    handleDrawerOpen: () => void;
}

const MainHeader: React.FC<HeaderProps> = ({ open, handleDrawerOpen }) => {
    const drawerWidth = 400;
    const theme = useTheme();
    const appToken = parseJwt(API.getToken());
    const userName = appToken?.name;

    const stringAvatar = (name: string) => {
        return {
            children: `${name?.split(" ")[0]?.[0]?.toUpperCase()}${name
                ?.split(" ")[1]?.[0]
                ?.toUpperCase()}`
        };
    }

    const DrawerHeader = styled("div")(({ theme }) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 0),
        ...theme.mixins.toolbar
    }));

    const [showBackground, setShowBackground] = useState<boolean>(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 30) {
                setShowBackground(true);
            } else {
                setShowBackground(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    
    return (
        <DrawerHeader>
            <CustomAppBar
                theme={theme}
                color="transparent"
                sx={{
                    width: "100%",
                    ml: `${drawerWidth}px`,
                    display: !showBackground ? "block" : "none"
                }}
                className={style.head}
                position="fixed"
                open={open}
            >
                <Toolbar className={style.header}>
                    <div className={style.headerContent}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            data-testid="btnBurger"
                            onClick={handleDrawerOpen}
                            edge="start"
                            className={style.placeOrderNextBtn}
                            sx={{
                                height: 40,
                                marginRight: 5,
                                ...(open && { display: "none" })
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                    </div>
                    <Typography component="div">
                        <div className={style.headerProfile}>
                            <Avatar
                                {...stringAvatar(userName)}
                                className={style.avatarStyle}
                            />
                            <span className={style.profileName}>
                            {userName}
                            </span>
                        </div>
                    </Typography>
                </Toolbar>
            </CustomAppBar>
        </DrawerHeader>
    );
};

export default MainHeader;
