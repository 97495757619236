import ReactDOM from "react-dom/client";
import LoginLanding from "./LandingPage/LoginLanding";
import { Provider } from "react-redux";
import { MsalProvider } from "@azure/msal-react";
import { HashRouter } from "react-router-dom";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

import "./index.css";
import './i18n';
import { store } from "./store";
import theme from "./common/theme/theme";

const msalInstance = await PublicClientApplication.createPublicClientApplication(msalConfig)

const eventCallback = (event: any) => {
  if (
    (event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS) &&
    event.payload.account
  ) {
    msalInstance.setActiveAccount(event.payload.account);
  }
};

msalInstance.addEventCallback(eventCallback);

msalInstance.handleRedirectPromise();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <Provider store={store}>
    <MsalProvider instance={msalInstance}>
      <HashRouter>
        <ThemeProvider theme={theme}>
          <LoginLanding />
        </ThemeProvider>
      </HashRouter>
    </MsalProvider>
  </Provider>
);
