import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/system";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import { Outlet } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import style from "./LandingPage.module.css";
import logo from "../../assets/Icons/Logo.svg";
import MainHeader from "../../Components/Header/MainHeader";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { TRANS_KEYS } from "../../utils/Constants";
import { API } from "../../utils/API";
import { parseJwt } from "../../utils/utils";
import LeftMenu from "../LeftMenu/LeftMenu";
import { useTranslation } from "react-i18next";

interface LandingPageProps { }
const drawerWidth = 400;

const openedMixin = (theme: any) => ({
  width: drawerWidth,
  height: '100%',
  background: "transparent",
  boxShadow: "40px 0px 50px rgba(0,0,0,0.4)",
  transition: theme?.transitions?.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: "hidden"
});

const closedMixin = (theme: any) => ({
  background: "transparent",
  boxShadow: "40px 0px 50px rgba(0,0,0,0.2)",
  transition: theme.transitions?.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: "hidden",
  width: 0,
  [theme.breakpoints.up("sm")]: {
    width: 0
  }
});

const DrawerHeader = styled("div")(({ theme }: any) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins?.toolbar
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }: any) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme)
  })
}));
const LandingPage: React.FC<LandingPageProps> = () => {
  const {t} = useTranslation(TRANS_KEYS.translation, {keyPrefix: TRANS_KEYS.LEFT_MENU_FOOTER});
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const userName = parseJwt(API.getToken())?.name;  
  const firstName: string = userName?.split(' ')[0];

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      const browserZoomLevel = Math.round(window.devicePixelRatio * 100);
      if (browserZoomLevel > 250) {
        setOpen(false);
      } else {
        setOpen(true);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer variant="permanent" theme={theme} open={open}>
        <div>
          <DrawerHeader>
            <div>
            <div className={style.headerLogo} data-testid="logo">
              <img src={logo} alt="logo" />
            </div>
            <div className={style.profileLeftMenu}>
              {t('GREET')} <br />
              {firstName}
               <br />
            </div>
            </div>
            <div className={style.burgerButton}>
              <IconButton
                onClick={handleDrawerClose}
                data-testid="btnChevron"
                className={open ? style.chevronBtn : ''}
              >
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon data-testid="btnChevronRight" />
                ) : (
                  <ChevronLeftIcon data-testid="btnChevronLeft" />
                )}
              </IconButton>
            </div>
          </DrawerHeader>
        </div>
        <div className={style.leftMenuContainer}>
          <LeftMenu />
        </div>
        <div className={style.footer}>
          <div>
            <span className={style.footerEmail}><LocalPhoneIcon className={style.icon} /></span>
            <span className={style.footerPhoneContent}>{t('EMAIL')}</span>
          </div>
          <div>
            <span className={style.footerEmail}><MailOutlineIcon className={style.icon} /> </span>
            <span className={style.footerEmailContent}>{t('PHONE')}</span>
          </div>
        </div>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: "transparent",
          p: "0px 24px 0px 24px",
          overflow: "hidden",
          marginTop: "-1vh",
          zIndex: 1
        }}
      >
        <MainHeader open={open} handleDrawerOpen={handleDrawerOpen} />
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
};

export default LandingPage;
