import axios from "axios";
import {parseJwt} from "./utils";
import {ErrorCodes} from "./ErrorConstants";
import {IError} from "../common/type";
import {Dictionary} from "@reduxjs/toolkit";

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

interface IErrorData {
    errorMessage: string[],
    ErrorCode: string
}

interface IErrorResponse {
    data: IErrorData,
    status: number | null
}

interface INetworkError {
    message: string,
    response?: IErrorResponse
}

export function handleError(error: INetworkError): IError {
    let message = error.message;
    if (error?.response?.status === 400) {
        if (error.response.data.errorMessage != null) {
            message = error.response.data.errorMessage.toString();
        } else {
            message = ErrorCodes.BadRequest;
        }
        return {
            message,
            errorCode: ErrorCodes.BadRequest
        };
    } else if (error?.response?.status === 401) {
        return {
            message,
            errorCode: ErrorCodes.LoginRequired
        };
    } else if (error?.response?.status === 404) {
        return {
            message,
            errorCode: ErrorCodes.NotFound
        };
    } else if (error?.response?.status === 403) {
        return {
            message,
            errorCode: ErrorCodes.AccessDenied
        };
    } else {
        return {
            message,
            errorCode: ErrorCodes.UncaughtException
        };
    }
}

export const emailCheck = (token: string) => {
    if (!token) {
        throw handleError(Error(ErrorCodes.LoginRequired));
    }
    const parsedObject = parseJwt(token);
    if (!parsedObject?.unique_name) {
        throw Error(ErrorCodes.LogOutRequired);
    }
};

let accessToken: string | null = null;

export const API = {
    BATCHES: "batches",
    TICKET: "tickets",
    TRAILERS: "trailers",
    SITES: "sites",
    ALERT: "alerts",
    getToken: () => accessToken,

    setToken: (token: string) => {
        accessToken = token;
    },

    get: async (url: string, params: Dictionary<string>) => {
        accessToken && emailCheck(accessToken);
        try {
            const path = BASE_API_URL + url;
            return await axios.get(path, {
                headers: {Authorization: `Bearer ${accessToken}`}, params: params
            });
        } catch (error: any) {
            throw handleError(error);
        }
    },
    
    post: async (url: string, body: any) => {
        accessToken && emailCheck(accessToken);
        try {
            const path = BASE_API_URL + url;
            return await axios.post(path, body, {
                headers: {Authorization: `Bearer ${accessToken}`},
            });
        } catch (error: any) {
            throw handleError(error);
        }
    },
    put: async (url: string, body: any) => {
        accessToken && emailCheck(accessToken);
        try {
            const path = BASE_API_URL + url;
            return await axios.put(path, body, {
                headers: {Authorization: `Bearer ${accessToken}`},
            });
        } catch (error: any) {
            throw handleError(error);
        }
    },
    patch: async (url: string, body: any) => {
        accessToken && emailCheck(accessToken);
        try {
            const path = BASE_API_URL + url;
            return await axios.patch(path, body, {
                headers: {Authorization: `Bearer ${accessToken}`},
            });
        } catch (error: any) {
            throw handleError(error);
        }
    },
    delete: async (url: string) => {
        accessToken && emailCheck(accessToken);
        try {
            const path = BASE_API_URL + url;
            return await axios.delete(path, {
                headers: {Authorization: `Bearer ${accessToken}`},
            });
        } catch (error: any) {
            throw handleError(error);
        }
    },
};
